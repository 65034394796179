(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@lokidb/indexed-storage", [], factory);
	else if(typeof exports === 'object')
		exports["@lokidb/indexed-storage"] = factory();
	else
		root["@lokidb/indexed-storage"] = factory();
})(typeof self !== 'undefined' ? self : this, function() {
return 